#checklabel {
  font-family: Arial, Helvetica, sans-serif;
}
section {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("https://i.pinimg.com/originals/63/7a/4d/637a4d42e1738853b2f96ffbbd60622a.gif");
  background-repeat: no-repeat;
  background-size: cover;
}
.btn {
  background-color: #7464bc;
  width: 150px;
}
.btn:hover {
  background-color: #7464bc;
}
.mainheading {
  text-align: center;
}
